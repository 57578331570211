import React from 'react'
import PropTypes from 'prop-types'

/* eslint-disable react/jsx-wrap-multilines */
const Products = ({
  downloadProspectHandler,
  widthClass,
  scopeOnly,
}) => {
  return (
    <section className={`products ${widthClass}`}>
      <div className="row heading">
        <div className="col">{widthClass ? '' : 'PRODUCT'}</div>
        <div className="col">{widthClass ? '' : 'REQUIREMENTS'}</div>
        <div className="col" />
      </div>
      {widthClass || scopeOnly ? null : (
        <div className="row">
          <hr />
        </div>
      )}
      {scopeOnly ? null : (
        <div className="row">
          <div className="col title">
            <p>
              <b>Prospect Pro Plus</b>
            </p>
            <p>
              <b>Prospect Pro</b>
            </p>
            <p>
              <b>Prospect Basic</b>
            </p>
            <p>
              <b>Prospect Viewer</b>
            </p>
          </div>
          <div className="col">
            <ul>
              <li>Windows 7 SP1 64 bit or newer</li>
              <li>Meta Quest 2, 3, 3S, or Pro</li>
              <li>NVIDIA GTX 1070 or faster</li>
            </ul>
          </div>
          <div className="col">
            <button className="download-btn" onClick={downloadProspectHandler}>
              DOWNLOAD PROSPECT
            </button>
          </div>
        </div>
      )}
    </section>
  )
}

Products.propTypes = {
  widthClass: PropTypes.string.isRequired,
  downloadProspectHandler: PropTypes.func.isRequired,
  scopeOnly: PropTypes.bool.isRequired,
}

export default Products
